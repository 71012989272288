import { SummaryProps } from "props/summary.props";
import moment from "moment";
import Polygone from "./polygone.js";

export default function Summary(props: SummaryProps) {
  const year: string = moment().format("YYYY");
  return (
    <Polygone nodes={props.nodes} isMenu>
      {props.isUnderConstruction && (
        <>
          <label className="under-construction">under construction</label>
          <label className="cr">&copy; midoudream {year}</label>
        </>
      )}
    </Polygone>
  );
}
