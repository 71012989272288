import { Modal, Upload } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useContext, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import AlertContext from "context/alert.context";
import axios from "axios";
import Button from "@mui/material/Button";
import LoaderContext from "../context/loader.context";
import socket from "../socket/socket";
import type { RcFile } from "antd/es/upload";
import type { UploadFile } from "antd/es/upload/interface";

function UploadImageForm() {
  const navigate = useNavigate();

  const [, setLoading] = useContext(LoaderContext);
  const [, setMessage, , setSeverity, , setOpen] = useContext(AlertContext);
  const [files, setFiles] = useState<UploadFile[]>([]);
  const [previewImage, setPreviewImage] = useState<string>("");
  const [previewOpen, setPreviewOpen] = useState<boolean>(false);
  const [previewTitle, setPreviewTitle] = useState<string>("");

  useMemo(
    () =>
      socket.on("images-uploaded", (name) => {
        setMessage(`${name} uploaded`);
        setSeverity("success");
        setOpen(true);
      }),
    []
  );

  useMemo(
    () =>
      socket.on("images-not-uploaded", (name) => {
        setMessage(`${name} not uploaded`);
        setSeverity("error");
        setOpen(true);
      }),
    []
  );

  useMemo(
    () =>
      socket.on("all-uploaded", () => {
        setFiles([]);
        setLoading(false);
        setMessage("Uploaded");
        setSeverity("success");
        setOpen(true);
        navigate("/images");
      }),
    []
  );

  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  const handlePreview = async (file: UploadFile) => {
    file.preview = await getBase64(file.originFileObj as RcFile);
    setPreviewImage(file.preview || "");
    setPreviewOpen(true);
    setPreviewTitle(file.name);
  };

  const handleChange = ({ fileList: newFiles }) => {
    setFiles(newFiles);
  };

  const beforeUpload = (file) => {
    if (!["image/jpg", "image/jpeg", "image/png"].includes(file.type)) {
      setPreviewTitle(file.name + " is not an image");
      setPreviewOpen(true);
      setPreviewImage("");
      return Upload.LIST_IGNORE;
    }
    return false;
  };

  const onUpload = () => {
    const formData = new FormData();
    files.forEach((e: UploadFile) => {
      formData.append("files", e.originFileObj as RcFile, e.fileName);
    });
    formData.append("socketId", socket.id as string);
    setLoading(true);

    axios
      .post("/api/images", formData, {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then(() => undefined)
      .catch((e) => {
        setLoading(false);
        setMessage(e?.response?.data?.message);
        setSeverity("error");
        setOpen(true);
      });
  };

  return (
    <>
      <div className="upload-cont">
        <Upload
          beforeUpload={beforeUpload}
          className="upload-span"
          fileList={files}
          listType="picture-card"
          multiple
          onChange={handleChange}
          onPreview={handlePreview}
        >
          <PlusOutlined />
        </Upload>
        <Modal
          open={previewOpen}
          title={previewTitle}
          footer={null}
          onCancel={() => setPreviewOpen(false)}
        >
          <img alt="" style={{ width: "100%" }} src={previewImage} />
        </Modal>
        <Button
          variant="outlined"
          onClick={onUpload}
          className="upload-btn"
          disabled={files.length === 0}
        >
          Upload
        </Button>
      </div>
    </>
  );
}

export default UploadImageForm;
